import { Button, Dialog } from "components/ui"
import { useState } from "react"
import { Trans } from "react-i18next"



const TbModal = ({children,className,bodyOpenClassName, isOpen, onClose,overlayClose = true, closable = false,width}) => {

    return (
        <>
            <Dialog
				isOpen={isOpen}
				onClose={onClose}
				onRequestClose={onClose}
                className={className}
				bodyOpenClassName={bodyOpenClassName}
                shouldCloseOnOverlayClick={overlayClose}
				closable={closable}
				width={width}> 
				<div className="p-4">
					{children}	

				</div>
			</Dialog>
        </>
    )
}

export default TbModal