const appConfig = {
    appName : 'Tsedbox',
    apiPrefix: 'https://api.osher-tsedbox.fr/api/remote/',
    distantPrefix: 'https://api.osher-tsedbox.fr/',
    authenticatedEntryPath: '/waiting',
    defaultCurrency : '€',
    // unAuthenticatedEntryPath: '/sign-in',
    unAuthenticatedEntryPath: '/waiting',
    tourPath: '/app/account/kyc-form',
    enableMock: true,
    entrepriseSearchApi : 'https://recherche-entreprises.api.gouv.fr/search'
}

export default appConfig