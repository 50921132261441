import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { apiGetPlayersOptions, apiGetTerminalOptions } from 'services/TsedboxService'

export const initialState = {
    terminal : {},
    players : [],
    prices : [],
    terminal_companies : [],
    companyTerminal : null,
    loading : false,
    currentPlayer: 0
}
export const getTerminalOptions = createAsyncThunk('terminal/data/getTerminalOptions',async (guid) => {
    const response = await apiGetTerminalOptions(guid)
    return response
})

export const getPlayersOptions = createAsyncThunk('terminal/data/getPlayersOptions',async (guid) => {
    const response = await apiGetPlayersOptions(guid)
    return response
})

export const terminalSlice = createSlice({
	name: 'base/terminal',
	initialState,
    reducers: { 
        setPlayers: (state, action) => {
            state.players = action.payload
        },
        setPrices: (state, action) => {
            state.prices = action.payload
        },
        setCurrentPlayer: (state, action) => {
            state.currentPlayer = action.payload
        },
        setCompanyTerminal: (state, action) => {
            state.companyTerminal = action.payload
        }

    },
    extraReducers: {
        
        [getTerminalOptions.fulfilled] : (state, action) => {
            state.terminal = action.payload.terminal;
            state.players = action.payload.players;
            state.prices = action.payload.prices;
            state.terminal_companies = action.payload.terminal_companies;
            state.loading = false;
            let terminal =action.payload.terminal;
            document.documentElement.style.setProperty('--t-primary-color', terminal.t_primary_color);
            document.documentElement.style.setProperty('--t-donation-title-color', terminal.t_donation_title_color);
            document.documentElement.style.setProperty('--t-title-color', terminal.t_title_color);
            document.documentElement.style.setProperty('--t-background-color', terminal.t_background_color);
            document.documentElement.style.setProperty('--t-prices-slider-color', terminal.t_prices_slider_color);
            document.documentElement.style.setProperty('--t-prices-background-color', terminal.t_prices_background_color);
            document.documentElement.style.setProperty('--t-current-price-background-color', terminal.t_current_price_background_color);
            document.documentElement.style.setProperty('--t-current-price-color', terminal.t_current_price_color);
            document.documentElement.style.setProperty('--t-validation-color', terminal.t_validation_color);
            document.documentElement.style.setProperty('--t-validation-text-color', terminal.t_validation_text_color);

            
            
        },
        [getTerminalOptions.pending]: (state) => {
            state.loading = true
        },

        [getPlayersOptions.fulfilled] : (state, action) => {
            state.players = action.payload;
        }
    }
})

export const { setPlayers,setPrices,setCurrentPlayer, setCompanyTerminal } = terminalSlice.actions

export default terminalSlice.reducer