import { THEME_ENUM } from 'constants/theme.constant'

/**
 * Since some configurations need to be match with specific themes, 
 * we recommend to use the configuration that generated from demo.
 */

export const themeConfig = {
    themeColor: 'tsedbox',
    defaultColor: "#B48D56",
    direction: THEME_ENUM.DIR_LTR,
    mode: THEME_ENUM.MODE_LIGHT,
    locale: 'fr',
    primaryColorLevel: 600,
    cardBordered: true,
    panelExpand: false,
    controlSize: 'md',
    navMode: THEME_ENUM.NAV_MODE_THEMED,
    layout: {
        type: THEME_ENUM.LAYOUT_TYPE_MODERN,
        sideNavCollapse: false,
    },
}


export const subscriptionsThemeConfig = [
    {
        plan : "free",
        cardClass : "border border-2 border-noota-blue-600 bg-transparent text-black"
    },
    {
        plan : "solo",
        cardClass : "bg-noota-purple-light-gradient text-white"
    },
    {
        plan : "pro",
        cardClass : "bg-noota-purple-gradient text-white"
    },
    {
        plan : "team",
        cardClass : "bg-noota-cyan-gradient text-white"
    }
]