import { combineReducers } from '@reduxjs/toolkit'
import common from './commonSlice'
import socket from './socketSlice'
import terminal from './terminalSlice'

const reducer = combineReducers({
    common,
    socket,
    terminal
})

export default reducer