export const hazeDashboardData = {
    statisticData: {
        revenue: {
            value: 21827.13,
            growShrink: 11.4
        },
        orders: {
            value: 1758,
            growShrink: -3.2
        },
        purchases: {
            value: 7249.31,
            growShrink: 5.7
        },
    },
    hazeReportData: {
        series: [
            {
                name: "Online Sales",
                data: [24, 33, 29, 36, 34, 43, 40, 47, 45, 48, 46, 55]
            },
            {
                name: "Marketing Sales",
                data: [20, 26, 23, 24, 22, 29, 27, 36, 32, 35, 32, 38]
            }
        ],
        categories:[
            '01 Jan', 
            '02 Jan', 
            '03 Jan', 
            '04 Jan', 
            '05 Jan', 
            '06 Jan', 
            '07 Jan', 
            '08 Jan', 
            '09 Jan',
            '10 Jan', 
            '11 Jan', 
            '12 Jan'
        ]
    },
    topProductsData: [
        {
            id: '12',
            name: 'Luminaire Giotto Headphones',
            img: '/img/products/product-1.jpg',
            sold: 252,
        },
        {
            id: '14',
            name: 'Black Sneaker',
            img: '/img/products/product-3.jpg',
            sold: 186,
        },
        {
            id: '15',
            name: 'Gray Hoodies',
            img: '/img/products/product-4.jpg',
            sold: 166,
        },
        {
            id: '16',
            name: 'Blue Backpack',
            img: '/img/products/product-5.jpg',
            sold: 93,
        },
        {
            id: '18',
            name: 'Strip Analog Watch',
            img: '/img/products/product-7.jpg',
            sold: 81,
        }
    ],
    latestOrderData: [
        {
            id: '95954',
            date: 1660132800,
            customer: 'Ron Vargas',
            status: 0,
            paymentMehod: 'visa',
            paymentIdendifier: '•••• 6165',
            totalAmount: 168,
        },
        {
            id: '95423',
            date: 1659132800,
            customer: 'Carolyn Hanso',
            status: 0,
            paymentMehod: 'visa',
            paymentIdendifier: '•••• 7128',
            totalAmount: 523
        },
        {
            id: '92903',
            date: 1658132800,
            customer: 'Gabriella May',
            status: 1,
            paymentMehod: 'paypal',
            paymentIdendifier: '••••@gmail.com',
            totalAmount: 81
        },
        {
            id: '92627',
            date: 1657332800,
            customer: 'Tara Fletcher',
            status: 2,
            paymentMehod: 'master',
            paymentIdendifier: '•••• 0921',
            totalAmount: 279
        },
        {
            id: '89332',
            date: 1654132800,
            customer: 'Eileen Horton',
            status: 0,
            paymentMehod: 'paypal',
            paymentIdendifier: '••••@gmail.com',
            totalAmount: 597
        },
        {
            id: '86497',
            date: 1647632800,
            customer: 'Lloyd Obrien',
            status: 2,
            paymentMehod: 'visa',
            paymentIdendifier: '•••• 0443',
            totalAmount: 189
        },
        {
            id: '86212',
            date: 1646832800,
            customer: 'Tara Fletcher',
            status: 0,
            paymentMehod: 'paypal',
            paymentIdendifier: '••••@gmail.com',
            totalAmount: 672
        }
    ],
    hazeByCategoriesData: {
        labels: ['Devices', 'Watches', 'Bags', 'Shoes'],
        data: [351, 246, 144, 83]
    }
}

export const clientsData = [
	{
        id: '12',
        name: 'Loiseau Henry S.A.R.L. SAS',
        email: "reynaud.lucy@collet.fr",
        siret: "264 534 785 96001",
        productCode: 'BIS-012',
        createdTime: 1623495000,
        img: 'https://camo.githubusercontent.com/ed950e04d5f6d7e8e353b67033e9d995b5ae9e57546b32f47b873e403fba20bd/68747470733a2f2f692e696d6775722e636f6d2f326950363873362e6a7067',
        imgList: [
            {
                id: '12-img-0',
                name: 'image-1',
                img: '/img/products/product-1.jpg'
            }
        ],
        category: 'devices',
        price: 252,
        stock: 46,
        status: 0,
        costPerItem: 12,
        bulkDiscountPrice: 68,
        taxRate: 6,
        tags: ['trend', 'unisex'],
        brand: 'Luminaire',
        vendor: 'WindForce co, Ltd',
        description: '<p>Make a brew a right royal knees up and we all like figgy pudding a comely wench gutted its nicked pulled out the eating irons, ask your mother if on goggle box toad in the whole Sherlock rather, ar kid pennyboy naff superb pezzy little. </p><br/><ul><li>Scally utter shambles blighty squirrel numbskull rumpy pumpy apple and pears bow ties are cool</li><li>pompous nosh have a butcher at this flabbergasted a right toff black cab jolly good made a pigs ear of it</li><li>Roast beef conked him one on the nose had a barney with the inlaws beefeater is she avin a laugh supper, gobsmacked argy-bargy challenge you to a duel</li><li>whizz air one dirty linen chav not some sort of dosshouse.</li></ul>',
    },
    {
        id: '13',
        name: 'Petitjean SARL',
        email: "nguyen.francoise@david.fr",
        siret: "104 021 472 00485",
        productCode: 'BIS-017',
        createdTime: 1623494000,
        img: 'https://camo.githubusercontent.com/03f8fd793ec3e79dc7930e5b19920e30f51bcfdc9ad669701676a75fbc4698e7/68747470733a2f2f692e696d6775722e636f6d2f364d696b304e4e2e6a7067',
        
        imgList: [
            {
                id: '13-img-0',
                name: 'image-1',
                img: '/img/products/product-2.jpg'
            },
            {
                id: '13-img-1',
                name: 'image-2',
                img: '/img/products/product-2-2.jpg'
            }
        ],
        category: 'bags',
        price: 139,
        stock: 28,
        status: 0,
        costPerItem: 12,
        bulkDiscountPrice: 68,
        taxRate: 6,
        tags: ['trend', 'unisex'],
        brand: 'Adidas',
        vendor: 'WindForce co, Ltd',
        description: '<p>Make a brew a right royal knees up and we all like figgy pudding a comely wench gutted its nicked pulled out the eating irons, ask your mother if on goggle box toad in the whole Sherlock rather, ar kid pennyboy naff superb pezzy little. </p><br/><ul><li>Scally utter shambles blighty squirrel numbskull rumpy pumpy apple and pears bow ties are cool</li><li>pompous nosh have a butcher at this flabbergasted a right toff black cab jolly good made a pigs ear of it</li><li>Roast beef conked him one on the nose had a barney with the inlaws beefeater is she avin a laugh supper, gobsmacked argy-bargy challenge you to a duel</li><li>whizz air one dirty linen chav not some sort of dosshouse.</li></ul>',
    },
    {
        id: '14',
        name: 'Mercier SAS SA',
        email: "bourgeois.david@lesage.fr",
        siret: "123 308 959 17828",
        productCode: 'BIS-080',
        createdTime: 1623493000,
        img: 'https://camo.githubusercontent.com/c28099f2d4b7ea58af6a8f4feb7f4e05c362b8c000eb8b9cb64f4d6e7cb651fb/68747470733a2f2f692e696d6775722e636f6d2f714231776d61782e6a7067',
        

        imgList: [
            {
                id: '14-img-0',
                name: 'image-1',
                img: '/img/products/product-3.jpg'
            }
        ],
        category: 'shoes',
        price: 99,
        stock: 52,
        status: 0,
        costPerItem: 12,
        bulkDiscountPrice: 68,
        taxRate: 6,
        tags: ['trend', 'unisex'],
        brand: 'Adidas',
        vendor: 'WindForce co, Ltd',
        description: '<p>Make a brew a right royal knees up and we all like figgy pudding a comely wench gutted its nicked pulled out the eating irons, ask your mother if on goggle box toad in the whole Sherlock rather, ar kid pennyboy naff superb pezzy little. </p><br/><ul><li>Scally utter shambles blighty squirrel numbskull rumpy pumpy apple and pears bow ties are cool</li><li>pompous nosh have a butcher at this flabbergasted a right toff black cab jolly good made a pigs ear of it</li><li>Roast beef conked him one on the nose had a barney with the inlaws beefeater is she avin a laugh supper, gobsmacked argy-bargy challenge you to a duel</li><li>whizz air one dirty linen chav not some sort of dosshouse.</li></ul>',
    },
    {
        id: '15',
        name: 'Marin S.A. et Fils',
        email: "xavier68@valentin.com",
        siret: "622 531 127 06601",
        productCode: 'BIS-025',
        createdTime: 1623491000,
        img: 'https://camo.githubusercontent.com/0852469fe75e77c77ecd1f9954f6b6d2f7dee3bbb1b59eb179a9fc5149b2fef9/68747470733a2f2f692e696d6775722e636f6d2f6a4763596849412e6a7067',
        

        imgList: [
            {
                id: '15-img-0',
                name: 'image-1',
                img: '/img/products/product-4.jpg'
            }
        ],
        category: 'cloths',
        price: 68,
        stock: 92,
        status: 0,
        costPerItem: 12,
        bulkDiscountPrice: 68,
        taxRate: 6,
        tags: ['trend', 'unisex'],
        brand: 'Adidas',
        vendor: 'WindForce co, Ltd',
        description: '<p>Make a brew a right royal knees up and we all like figgy pudding a comely wench gutted its nicked pulled out the eating irons, ask your mother if on goggle box toad in the whole Sherlock rather, ar kid pennyboy naff superb pezzy little. </p><br/><ul><li>Scally utter shambles blighty squirrel numbskull rumpy pumpy apple and pears bow ties are cool</li><li>pompous nosh have a butcher at this flabbergasted a right toff black cab jolly good made a pigs ear of it</li><li>Roast beef conked him one on the nose had a barney with the inlaws beefeater is she avin a laugh supper, gobsmacked argy-bargy challenge you to a duel</li><li>whizz air one dirty linen chav not some sort of dosshouse.</li></ul>',
    },
    {
        id: '16',
        name: 'Paris S.A.S.',
        email: "ccordier@chevalier.com",
        siret: "279 274 393 02178",
        productCode: 'BIS-010',
        createdTime: 1623485000,
        img: 'https://camo.githubusercontent.com/848274fe9189a941da73fb6a33765675ac6ee9f7b8356b3c2b1a4a9829107578/68747470733a2f2f692e696d6775722e636f6d2f39425a636570642e6a7067',
        
        imgList: [
            {
                id: '16-img-0',
                name: 'image-1',
                img: '/img/products/product-5.jpg'
            }
        ],
        category: 'bags',
        price: 70,
        stock: 0,
        status: 2,
        costPerItem: 12,
        bulkDiscountPrice: 68,
        taxRate: 6,
        tags: ['trend', 'unisex'],
        brand: 'Adidas',
        vendor: 'WindForce co, Ltd',
        description: '<p>Make a brew a right royal knees up and we all like figgy pudding a comely wench gutted its nicked pulled out the eating irons, ask your mother if on goggle box toad in the whole Sherlock rather, ar kid pennyboy naff superb pezzy little. </p><br/><ul><li>Scally utter shambles blighty squirrel numbskull rumpy pumpy apple and pears bow ties are cool</li><li>pompous nosh have a butcher at this flabbergasted a right toff black cab jolly good made a pigs ear of it</li><li>Roast beef conked him one on the nose had a barney with the inlaws beefeater is she avin a laugh supper, gobsmacked argy-bargy challenge you to a duel</li><li>whizz air one dirty linen chav not some sort of dosshouse.</li></ul>',
    },
    {
        id: '17',
        name: 'Paris S.A.S.',
        email: "ccordier@chevalier.com",
        siret: "279 274 393 02178",
        productCode: 'BIS-015',
        createdTime: 1623482000,
        img: 'https://camo.githubusercontent.com/848274fe9189a941da73fb6a33765675ac6ee9f7b8356b3c2b1a4a9829107578/68747470733a2f2f692e696d6775722e636f6d2f39425a636570642e6a7067',
        
        imgList: [
            {
                id: '16-img-0',
                name: 'image-1',
                img: '/img/products/product-5.jpg'
            }
        ],
        category: 'bags',
        price: 70,
        stock: 0,
        status: 2,
        costPerItem: 12,
        bulkDiscountPrice: 68,
        taxRate: 6,
        tags: ['trend', 'unisex'],
        brand: 'Adidas',
        vendor: 'WindForce co, Ltd',
        description: '<p>Make a brew a right royal knees up and we all like figgy pudding a comely wench gutted its nicked pulled out the eating irons, ask your mother if on goggle box toad in the whole Sherlock rather, ar kid pennyboy naff superb pezzy little. </p><br/><ul><li>Scally utter shambles blighty squirrel numbskull rumpy pumpy apple and pears bow ties are cool</li><li>pompous nosh have a butcher at this flabbergasted a right toff black cab jolly good made a pigs ear of it</li><li>Roast beef conked him one on the nose had a barney with the inlaws beefeater is she avin a laugh supper, gobsmacked argy-bargy challenge you to a duel</li><li>whizz air one dirty linen chav not some sort of dosshouse.</li></ul>',
    }
]

export const suppliersData = [
	{
        id: 1,
        supplierName: 'EDF',
        eld: true,
        isPartner:true,
        elecProvider: true,
        gazProvider: true,
        paymentMethod: "Virement",
        contact : {
            name: "John Doe",
            email: "reynaud.lucy@collet.fr",
            phone: "0245564564"
        },
        validatedContract: 9,
        terminationNotice: 60,
        
    },
    {
        id: 2,
        supplierName: 'TOTAL',
        eld: true,
        isPartner:false,
        elecProvider: true,
        gazProvider: true,
        paymentMethod: "Virement",
        contact : {
            name: "Patrick Doe",
            email: "pdoe@carver.fr",
            phone: "0245564564"
        },
        validatedContract: 4,
        terminationNotice: 60
    },
    {
        id: 3,
        supplierName: 'ENGIE',
        eld: true,
        isPartner:true,
        elecProvider: true,
        gazProvider: true,
        paymentMethod: "Virement",
        contact : {
            name: "Albert Doe",
            email: "adoe@carver.fr",
            phone: "0245564564"
        },
        validatedContract: 22,
        terminationNotice: 60
    },{
        id: 4,
        supplierName: 'ENI',
        eld: false,
        isPartner:true,
        elecProvider: true,
        gazProvider: true,
        paymentMethod: "Virement",
        contact : {
            name: "Gary Bodwick",
            email: "gbodwick@seekonk.fr",
            phone: "0245564564"
        },
        validatedContract: 4,
        terminationNotice: 60
    },{
        id: 5,
        supplierName: 'ENEDIS',
        eld: true,
        isPartner:true,
        elecProvider: true,
        gazProvider: true,
        paymentMethod: "Virement",
        contact : {
            name: "Chirstophe Leroy",
            email: "cleroy@carver.fr",
            phone: "0245564564"
        },
        validatedContract: 13,
        terminationNotice: 60
    },
]

export const ordersData = [
    {
        id: '95954',
        date: 1660132800,
        customer: 'Ron Vargas',
        status: 0,
        paymentMehod: 'visa',
        paymentIdendifier: '•••• 6165',
        totalAmount: 168,
    },
    {
        id: '95423',
        date: 1659132800,
        customer: 'Carolyn Hanso',
        status: 0,
        paymentMehod: 'visa',
        paymentIdendifier: '•••• 7128',
        totalAmount: 523
    },
    {
        id: '92903',
        date: 1658132800,
        customer: 'Gabriella May',
        status: 0,
        paymentMehod: 'paypal',
        paymentIdendifier: '••••@gmail.com',
        totalAmount: 81
    },
    {
        id: '92627',
        date: 1657332800,
        customer: 'Tara Fletcher',
        status: 0,
        paymentMehod: 'master',
        paymentIdendifier: '•••• 0921',
        totalAmount: 279
    },
    {
        id: '92509',
        date: 1656232800,
        customer: 'Joyce Freeman',
        status: 1,
        paymentMehod: 'visa',
        paymentIdendifier: '•••• 1232',
        totalAmount: 831
    },
    {
        id: '91631',
        date: 1655532800,
        customer: 'Brittany Hale',
        status: 0,
        paymentMehod: 'visa',
        paymentIdendifier: '•••• 4597',
        totalAmount: 142
    },
    {
        id: '90963',
        date: 1654932800,
        customer: 'Luke Cook',
        status: 0,
        paymentMehod: 'master',
        paymentIdendifier: '•••• 3881',
        totalAmount: 232
    },
    {
        id: '89332',
        date: 1654132800,
        customer: 'Eileen Horton',
        status: 1,
        paymentMehod: 'paypal',
        paymentIdendifier: '••••@gmail.com',
        totalAmount: 597
    },
    {
        id: '89107',
        date: 1650132800,
        customer: 'Frederick Adams',
        status: 2,
        paymentMehod: 'visa',
        paymentIdendifier: '•••• 3356',
        totalAmount: 72
    },
    {
        id: '89021',
        date: 1649832800,
        customer: 'Lee Wheeler',
        status: 0,
        paymentMehod: 'master',
        paymentIdendifier: '•••• 9564',
        totalAmount: 110
    },
    {
        id: '88911',
        date: 1649432800,
        customer: 'Gail Barnes',
        status: 0,
        paymentMehod: 'visa',
        paymentIdendifier: '•••• 1357',
        totalAmount: 59
    },
    {
        id: '87054',
        date: 1647932800,
        customer: 'Ella Robinson',
        status: 0,
        paymentMehod: 'visa',
        paymentIdendifier: '•••• 3561',
        totalAmount: 238
    },
    {
        id: '86497',
        date: 1647632800,
        customer: 'Lloyd Obrien',
        status: 2,
        paymentMehod: 'visa',
        paymentIdendifier: '•••• 0443',
        totalAmount: 189
    },
    {
        id: '86212',
        date: 1646832800,
        customer: 'Tara Fletcher',
        status: 0,
        paymentMehod: 'paypal',
        paymentIdendifier: '••••@gmail.com',
        totalAmount: 672
    }
]

export const orderDetailsData = [
    {
        id: '95954',
        progressStatus: 0,
        payementStatus: 0,
        dateTime: 1646396117,
        paymentSummary: {
            subTotal: 1762,
            tax: 105.72,
            deliveryFees: 15,
            total: 1870.72
        },
        shipping: {
            deliveryFees: 15,
            estimatedMin: 1,
            estimatedMax: 3,
            shippingLogo:  '/img/others/img-11.jpg',
            shippingVendor: 'FedEx'
        },
        activity: [
            {
                date: 1646554397,
                events: [
                    {
                        time: 1646554397,
                        action: 'Parcel has been delivered',
                        recipient: 'Lloyd Obrien'
                    },
                    {
                        time: 1646537537,
                        action: 'Parcel is out for delivery'
                    },
                    {
                        time: 1646529317,
                        action: 'Parcel has arrived at delivery station'
                    }
                ]
            },
            {
                date: 1646442017,
                events: [
                    {
                        time: 1646462597,
                        action: 'Parcel has been picked up by courier',
                    },
                    {
                        time: 1646537537,
                        action: 'Seller is preparing to ship your parcel'
                    },
                ]
            }
        ],
        product: [
            {
                id: '13',
                name: 'White Backpack',
                productCode: 'BIS-013',
                img: '/img/products/product-2.jpg',
                price: 252,
                quantity: 2,
                total: 504,
                details: {
                    color: ['White'],
                    size: ['One size']
                }
            },
            {
                id: '18',
                name: 'Strip Analog Watch',
                productCode: 'BIS-018',
                img: '/img/products/product-7.jpg',
                price: 389,
                quantity: 1,
                total: 389,
                details: {
                    color: ['Red'],
                    gender: ['Unisex']
                }
            },
            {
                id: '19',
                name: 'Beats Solo Headphone',
                productCode: 'BIS-019',
                img: '/img/products/product-8.jpg',
                price: 869,
                quantity: 1,
                total: 869,
                details: {
                    color: ['Red'],
                }
            }
        ],
        customer: {
            name: 'Lloyd Obrien',
            email: 'handsome-obrien@hotmail.com',
            phone: '+1 (541) 754-3010',
            img: '/img/avatars/thumb-11.jpg',
            previousOrder: 11,
            shippingAddress: {
                line1: '100 Main ST',
                line2: 'PO Box 1022',
                line3: 'Seattle WA 98104',
                line4: 'United States of America',
            },
            billingAddress: {
                line1: '1527 Pond Reef Rd',
                line2: 'Ketchikan',
                line3: 'Alaska 99901',
                line4: 'United States of America',
            }
        }
    }
]