import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
    socket: '',
    streamOpen : false
}

export const socketSlice = createSlice({
	name: 'base/socket',
	initialState,
	reducers: {
        setSocket: (state, action) => {
            state.socket = action.payload
        },
        setStreamOpen: (state, action) => {
            state.streamOpen = action.payload
        }
	},
})

export const { setSocket,setStreamOpen } = socketSlice.actions

export default socketSlice.reducer