export const signInUserData = [
    {
        id: '21',
        avatar: '/img/avatars/thumb-4.jpg',
        userName: 'Imri Balourd',
        email: 'truffin@example.com',
        authority: ['admin', 'user'],
        password: '123Qwe',
        accountUserName: 'admin'
    },
    {
        id: '22',
        avatar: '/img/avatars/thumb-4.jpg',
        userName: 'Imri Balourd',
        email: 'ibalourd@intc-engineering.com',
        authority: ['admin', 'user'],
        password: '12546',
        accountUserName: 'sudo'
    }
]