const y = new Date().getFullYear()

export const recordsList = [
	{
		id: 1,
		name: "Alexandre Duffaut",
		duration: '07:15',
		image_url: "/img/noota/image-test-noota.jpg",
		user_image_url: "/img/noota/default-user.png",
		created_at : "2022-12-13 14:25:00",
		preview : "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
		updated_at : "2022-12-13 18:45:00",
		status : "in_process",
		type : "video"
	},
	{
		id: 2,
		name: "Alexandre Duffaut",
		duration: '07:15',
		image_url: "/img/noota/image-test-noota.jpg",
		user_image_url: "/img/noota/default-user.png",
		created_at : "2022-12-13 14:25:00",
		preview : "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
		updated_at : "2022-12-13 18:45:00",
		status : "in_process",
		type : "video"
	},{
		id: 3,
		name: "Alexandre Duffaut",
		duration: '07:15',
		image_url: "/img/noota/image-test-noota.jpg",
		user_image_url: "/img/noota/default-user.png",
		created_at : "2022-12-13 14:25:00",
		preview : "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
		updated_at : "2022-12-13 18:45:00",
		status : "in_process",
		type : "subtitles"
	},{
		id: 4,
		name: "Alexandre Duffaut",
		duration: '07:15',
		image_url: "/img/noota/image-test-noota.jpg",
		user_image_url: "/img/noota/default-user.png",
		created_at : "2022-12-13 14:25:00",
		preview : "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
		updated_at : "2022-12-13 18:45:00",
		status : "in_process",
		type : "video"
	},{
		id: 5,
		name: "Alexandre Duffaut",
		duration: '10:15',
		image_url: "/img/noota/image-test-noota.jpg",
		user_image_url: "/img/noota/default-user.png",
		created_at : "2022-11-20 12:25:00",
		preview : "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
		updated_at : "2022-11-25 18:12:00",
		status : "in_process",
		type : "audio"
	},{
		id: 6,
		name: "Alexandre Duffaut",
		duration: '07:15',
		image_url: "/img/noota/image-test-noota.jpg",
		user_image_url: "/img/noota/default-user.png",
		created_at : "2022-12-13 14:25:00",
		preview : "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
		updated_at : "2022-12-13 18:45:00",
		status : "in_process",
		type : "video"
	},{
		id: 7,
		name: "Alexandre Duffaut",
		duration: '04:15',
		image_url: "/img/noota/image-test-noota.jpg",
		user_image_url: "/img/noota/default-user.png",
		created_at : "2022-12-08 12:25:00",
		preview : "Lorem ipsum dolor sit amet, consectetur adipiscing elit.",
		updated_at : "2022-12-12 14:45:00",
		status : "complete",
		type : "audio"
	},
]
 

export const subscriptionsList = [
	{
		id: 1,
		name : "Free",
		slug : "free",
		description : "",
		desc_trans_key: "free_plan_description",
		monthly_price : 0,
		annual_price : 0,
		is_active: true,
		included : true,
		options : [
			{
				name : "600 mins trial", 
				trans_key : "subscriptions.options.600_mins_trial"
			},
			{
				name : "10€/hr for transcription credit", 
				trans_key : "subscriptions.options.10_hours_for_transcription_credit"
			},
		]
	},
	{
		id: 2,
		name : "Solo",
		slug : "solo",
		description : "Individuals & Professionals who occasionally transcribe",
		desc_trans_key: "solo_plan_description",
		monthly_price : 22,
		annual_price : 0,
		is_active: false,
		included : false,
		options : [
			{
				name : "1500min per month", 
				trans_key : "subscriptions.options.1500_min_per_month"
			},
			{
				name : "5€/h for more", 
				trans_key : "subscriptions.options.5_euros_hours_for_more"
			},
		]
	},
	{
		id: 3,
		name : "Pro",
		slug : "pro",
		description : "Professionals with higher transcription volume",
		desc_trans_key: "solo_plan_description",
		monthly_price : 50,
		annual_price : 0,
		is_active: false,
		included : false,
		options : [
			{
				name : "Unlimited Transcription", 
				trans_key : "subscriptions.options.unlimited_transcription"
			},
			{
				name : "5€/h for more", 
				trans_key : "subscriptions.options.5_euros_hours_for_more"
			},
		]
	},
	{
		id: 4,
		name : "Team",
		slug : "team",
		description : "",
		monthly_price : "**",
		annual_price : 0,
		is_active: false,
		included : false,
		options : [
			{
				name : "Unlimited Transcription", 
				trans_key : "subscriptions.options.unlimited_transcription"
			},
		]
	},
]