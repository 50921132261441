import wildCardSearch from 'utils/wildCardSearch'
import sortBy from 'utils/sortBy'
import paginate from 'utils/paginate'

export default function hazeFakeApi (server, apiPrefix) {

    server.post(`${apiPrefix}/haze/dashboard`, (schema) => {
        return schema.db.salesDashboardData[0]
    })
    
    server.post(`${apiPrefix}/haze/products`, (schema, {requestBody}) => {
        const body = JSON.parse(requestBody)
        const { pageIndex, pageSize, sort, query } = body
        const { order, key } = sort
        const clients = schema.db.clientsData
        const sanitizeProducts = clients.filter(elm => typeof elm !== 'function')
        let data = sanitizeProducts
        let total = clients.length

        if((key === 'category' || key === 'name') && order) {
            data.sort(sortBy(key, order === 'desc' , (a) =>  a.toUpperCase()))
        } else {
            data.sort(sortBy(key, order === 'desc', parseInt))
        }

        if(query) {
            data = wildCardSearch(data, query)
            total = data.length
        }

        data = paginate(data, pageSize, pageIndex)

        const responseData = {
            data: data,
            total: total
        } 
        return responseData
    })

    server.del(`${apiPrefix}/haze/products/delete`, (schema, {requestBody}) => {
        const { id } = JSON.parse(requestBody)
        schema.db.productsData.remove({id})
        return true
    })

    server.get(`${apiPrefix}/haze/product`, (schema, {queryParams}) => {
        const id = queryParams.id
        const product = schema.db.productsData.find(id)
        return product
    })

    server.put(`${apiPrefix}/haze/products/update`, (schema, {requestBody}) => {
        const data = JSON.parse(requestBody)
        const { id } = data
        schema.db.productsData.update({id}, data)
        return true
    })

    server.post(`${apiPrefix}/haze/products/create`, (schema, {requestBody}) => {
        const data = JSON.parse(requestBody)
        schema.db.productsData.insert(data)
        return true
    })

    server.post(`${apiPrefix}/haze/suppliers`, (schema, {requestBody}) => {
        const body = JSON.parse(requestBody)
        const { pageIndex, pageSize, sort, query } = body
        const { order, key } = sort
        const suppliers = schema.db.suppliersData
        console.log("schema",schema.db);
        const sanitizeProducts = suppliers.filter(elm => typeof elm !== 'function')
        let data = sanitizeProducts
        let total = suppliers.length

        if((key === 'supplierName' || key === 'partner') && order) {
            data.sort(sortBy(key, order === 'desc' , (a) =>  a.toUpperCase()))
        } else {
            data.sort(sortBy(key, order === 'desc', parseInt))
        }

        if(query) {
            data = wildCardSearch(data, query)
            total = data.length
        }

        data = paginate(data, pageSize, pageIndex)

        const responseData = {
            data: data,
            total: total
        } 
        return responseData
    })

    server.get(`${apiPrefix}/haze/orders`, (schema, {queryParams}) => {
        const { pageIndex, pageSize, sort, query } = queryParams
        const { order, key } = JSON.parse(sort)
        const orders = schema.db.ordersData
        const sanitizeProducts = orders.filter(elm => typeof elm !== 'function')
        let data = sanitizeProducts
        let total = orders.length

        if(key) {
            if((key === 'date' || key === 'status' || key === 'paymentMehod') && order) {
                data.sort(sortBy(key, order === 'desc', parseInt))
            } else {
                data.sort(sortBy(key, order === 'desc' , (a) =>  a.toUpperCase()))
            }
        }

        if(query) {
            data = wildCardSearch(data, query)
            total = data.length
        }

        data = paginate(data, pageSize, pageIndex)
        
        const responseData = {
            data: data,
            total: total
        }
        return responseData
    })

    server.del(`${apiPrefix}/haze/orders/delete`, (schema, {requestBody}) => {
        const { id } = JSON.parse(requestBody)
        id.forEach(elm => {
            schema.db.ordersData.remove({id: elm})
        })
        return true
    })

    server.get(`${apiPrefix}/haze/orders-details`, (schema, {queryParams}) => {
        const { id } = queryParams
        const orderDetail = schema.db.orderDetailsData
        orderDetail[0].id = id
        return orderDetail[0]
    })
}